import { graphql, StaticQuery } from "gatsby";
import parse from "html-react-parser";
import React, { useState, useRef, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import LazyLoad from "react-lazyload";
import { Link as ScrollLink } from "react-scroll";
import heroImg from "../assets/images/SuperSummit-live-logo.png";
import bgvideo from "../assets/videos/Bg(1).mp4";
import CustomAccordion from "../components/common/accordian";
import Buttons from "../components/common/button";
import CarouselComponent from "../components/common/carousel-component";
import FormBuilder from "../components/common/FormBuilder/Form-Builder";
import handleFormSubmit from "../components/common/FormBuilder/Form-Function";
import Frame from "../components/common/frame";
import SVGIcon from "../components/common/SVGIcon";
import Navigation from "../components/navigation";
import "../styles/component/faq.scss";
import "../styles/component/pricing-faq.scss";
import "../styles/pages/Supersummit-2022.scss";
import "../styles/component/popup.scss";
import "../styles/component/forms.scss";
import lottie from "lottie-web/build/player/lottie_light.min.js"
import beeranimate from '../assets/images/animations/beeranimate.json';
import Laugh from "./../assets/images/laugh.svg";
import Popper from "./../assets/images/party-popper.svg";

// import bgvideo from "../assets/videos/Bg.mp4"

const Event = () => {
  const [popUp, setPopUp] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success,setSuccess]=useState(false)
  const loadRef = useRef();

  loadRef.current = setLoading;
  function showpopup(){
    setPopUp(true);
  }
  useEffect(()=>{
    setTimeout(showpopup,3000)
  },[])
  //   useEffect(() => {
  //   const anime=lottie.loadAnimation({
  //     container: document.querySelector(".text-center-anime"),
  //     loop: true,
  //     autoplay: true,
  //     renderer: "svg",
  //     animationData: beeranimate,
  //     rendererSettings: {
  //       preserveAspectRatio: "xMidYMid slice",
  //    },
  //   })
  //   return () => anime.destroy()
  // })

  const responsiveSpeaker = {
    mobile2: {
      breakpoint: { max: 535, min: 480 },
      items: 1.5,
    },
    mobile3: {
      breakpoint: { max: 480, min: 460 },
      items: 1.4,
    },
    mobile5: {
      breakpoint: { max: 460, min: 435 },
      items: 1.35,
    },
    mobile4: {
      breakpoint: { max: 435, min: 0 },
      items: 1.2,
    },
  };

  const responsive = {
    desktopLarge: {
      breakpoint: { max: 4000, min: 1200 },
      items: 2.8,
    },
    desktopSmall: {
      breakpoint: { max: 1200, min: 1024 },
      items: 3,
    },

    tablet: {
      breakpoint: { max: 1024, min: 500 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 578, min: 535 },
      items: 1.5,
    },

    mobile2: {
      breakpoint: { max: 535, min: 480 },
      items: 1.4,
    },
    mobile3: {
      breakpoint: { max: 480, min: 460 },
      items: 1.3,
    },
    mobile5: {
      breakpoint: { max: 460, min: 435 },
      items: 1.35,
    },
    mobile4: {
      breakpoint: { max: 435, min: 0 },
      items: 1.2,
    },
  };

  const formdata = [
    {
      name: "firstname",
      type: "text",
      label: "Name",
      errorMessage: "Please enter a name",
    },
    {
      name: "email",
      type: "email",
      label: "Email address",
      errorMessage: "Please enter a valid email",
    },
    // {
    //   name: "company",
    //   type: "text",
    //   label: "Company name",
    //   errorMessage: "Please enter a company name",
    // },

    // {
    //   name: "phone",
    //   type: "phone",
    //   label: "Mobile number",
    //   errorMessage: "Please enter a mobile number",
    // },
  ];

  async function handleSubmit(items) {
    const formData = items;
    setLoading(true);
    sessionStorage.setItem("superSummitName", items[1].value)
    sessionStorage.setItem("superSummitEmail", items[0].value)
    var successval=handleFormSubmit(
      formData,
      process.env.HUBSPOT_SUPERSUMMIT_LONDON_SUBSCRIPTION_ENDPOINT,
      process.env.HUBSPOT_SUPERSUMMIT_LONDON_URL,
      "Supersummit London Subscription - Superops.ai",
      null,
      null,
      // `success?refererName=${items[0].value}&&refererEmail=${items[1].value}`,
    );
    successval===true&&setSuccess(successval)
  }
  
  // async function handleSubmit(items) {
  //   let x = await formPromise(items).then(
  //     window.location = `/success?refererName=${items[0].value}&&refererEmail=${items[1].value}`
  //   )
  // }

  const Navbar = () => {
    return (
      <div className="Summit-nav-wrapper">
        <Container className="Summit-nav fixed-top">
          <div className="scroll-link d-dsk">
            <ScrollLink to="0">speakers</ScrollLink>
            <ScrollLink to="1">why attend</ScrollLink>
            <ScrollLink to="2">agenda</ScrollLink>
            <ScrollLink to="3">faq</ScrollLink>
          </div>
          <div>
            <SVGIcon name="logo-light" className="logo-light" />
          </div>
          <div className="d-dsk ">
            <Buttons
              theme="secondary white sm"
              text="REGISTER NOW"
              onClick={() => setPopUp(true)}
            />
          </div>
        </Container>
      </div>
    );
  };

  const InfoBox = (props) => {
    const { icon, info, heading } = props;
    return info.map((el, idx) => {
      return (
        <Col lg={2} xs={6}>
          <div className="info-box">
            <div>
              <img src={icon[idx].url} alt="icon" />
            </div>
            <div className="p14">
              <p>{el}</p>
            </div>
            <div className="p16">
              <p>{heading[idx]}</p>
            </div>
          </div>
        </Col>
      );
    });
  };

  const PopUpForm = () => {
    return (
      <div className="popup-signup">
        <div class="wrap position-fixed"></div>
        <Container className="position-fixed">
          <div className="Form-box position-relative">
            <SVGIcon
              name="close-icon-black"
              className="close-icon"
              onClick={() => setPopUp(false)}
            />
            {!success ? (
              <>
            <FormBuilder
              formClassName="forms"
              errorClassName="error-message"
              inputErrorClassName="error-active"
              data={formdata}
              formmessage="This event in now over. Subscribe to stay informed about all things SuperSummit"
              buttonText={
                loading ? (
                  <div className="loading-anim">
                    LOADING
                    <span className="ellipsis-anim">
                      <span>.</span>
                      <span>.</span>
                      <span>.</span>
                    </span>
                  </div>
                ) : (
                  "SUBMIT"
                )
              }
              buttonClassName="primary pink"
              onSubmit={(items) => handleSubmit(items)}
              IpStackData
            />
            <div className="disclaimer p12 a12">
              <center>
                <p>
                  By clicking "SUBMIT", you agree to SuperOps's{" "}
                  <a href="https://superops.ai/terms" target="_blank">
                    Terms of use
                  </a>{" "}
                  and{" "}
                  <a href="https://superops.ai/privacy" target="_blank">
                    Privacy policy
                  </a>
                  .
                </p>
              </center>
            </div> 
            </>) :
            (
              <>
                <div className="success-box text-center">
                  <img src={Popper} alt="popper" />

                  <p className="ty">
                  Thanks for subscribing! <br /> We'll keep you posted.
                  </p>

                  <div className="p16">
                    <p>
                      {" "}
                      Before you go, why don't you take a look at our{" "}
                      {/* <span>
                        <img src={Laugh} alt="laugh" />
                      </span>{" "} */}
                      <br /> <a href="https://supersummit.com/jan-2022/" target="_blank">on-demand virtual summit?</a>
                    </p>
                  </div>
                </div>
              </>
            )}
          </div>
        </Container>
      </div>
    );
  };

  const Cards = (props) => {
    const { icons, heading, content } = props;
    return (
      <div className="Cards">
        <img className="icon" src={icons} />
        <p className="heading">{heading}</p>
        <p className="content">{content}</p>
      </div>
    );
  };

  const SpeakerCards = (props) => {
    const { title, subtext, image } = props;
    return (
      <>
        <Col lg={4} xs={12}>
          <div className="speaker-card">
            <div className="speaker-image">
              <LazyLoad throttle={200} height={200}>
                <img src={image.url} alt="speaker" />
              </LazyLoad>
            </div>

            <p className="title">{title}</p>

            <p className="subtext">{subtext}</p>
          </div>
        </Col>
      </>
    );
  };

  return (
    <div>
      <StaticQuery
        query={graphql`
          query Event{
            SuperOps {
              pageComponents(where: { pageName: "SUPERSUMMIT - 2022" }) {
                hero {
                  heroImage {
                    url
                  }
                }
                seo {
                  keywords
                  description
                  title
                  image {
                    id
                    url
                  }
                }
                sectionName
                pageContents {
                  heading
                  subtext {
                    html
                  }
                  detailIcon {
                    url
                  }
                  detailContent
                  detailHeading
                }
                timeline {
                  time
                  agenda
                  title
                  speakerName
                  speakerTitle
                  speaker {
                    url
                  }
                }
                links {
                  linkName
                  icon {
                    url
                  }
                  authorName
                }
                faqs {
                  category
                  question
                  answerrt {
                    html
                  }
                }
                card {
                  image {
                    url
                  }
                  title
                  subtext
                }
                images{
                  url
                }
              }
            }
          }
        `}
        render={(data) => (
          <>
            {data.SuperOps.pageComponents.map((page) => {
              const {
                hero,
                seo,
                pageContents,
                timeline,
                links,
                faqs,
                card,
                sectionName,
                images
              } = page;
              return (
                <div className="Supersummit-2022 ">
                  <Frame
                    seo={seo}
                    FooterName="Super-Summit"
                    footerClassName="super-summit pt-0"
                    twitterOG
                  >
                    {popUp && <PopUpForm />}

                    <header>
                      <Navigation
                        page="Supersummit-2022"
                        tabs={[
                          { name: "speaker", id: "0" },
                          { name: "why attend", id: "1" },
                          { name: "agenda", id: "2" },
                          { name: "faq", id: "3" },
                        ]}
                        color={"transparent"}
                        setPopUp={setPopUp}
                      />
                    </header>

                    <section className="Hero position-relative">
                      <div className="gradient"></div>
                      <video
                        className="bg-video"
                        autoPlay
                        muted
                        loop
                        id="bg-video"
                      >
                        <source src={bgvideo} type="video/mp4" />
                      </video>
                      <Container>
                        <div className="wrapper position-relative">
                          <img src={heroImg} className="hero-img" alt="" />
                          <h2 className="hero-text">
                            {" "}
                            ROAD TO <br />1 MILLION
                          </h2>
                        </div>
                      </Container>
                    </section>
                    <div className="main-wrapper">
                      <section className="info-section">
                        <Container>
                          <center>
                            <Row className="justify-content-center">
                              {[links[0]].map((el, idx) => {
                                return (
                                  <InfoBox
                                    icon={el.icon}
                                    info={el.linkName}
                                    heading={el.authorName}
                                  />
                                );
                              })}
                            </Row>
                            {/* <Buttons
                              theme="primary pink register-button"
                              text="REGISTER NOW FOR FREE"
                              onClick={() => setPopUp(true)}
                            /> */}
                          </center>
                        </Container>
                      </section>

                      <section className="speakers" id="0">
                        <Container>
                          <div className="speakers-wrapper mx-auto">
                            <p className="text-pink">speakers</p>

                            <h4 className="speakers-heading">
                              Meet our speakers
                            </h4>
                            <div className="d-dsk">
                              <Row>
                                {card.title.map((el, idx) => {
                                  return (
                                    <SpeakerCards
                                      title={el}
                                      image={card.image[idx]}
                                      subtext={card.subtext[idx]}
                                    />
                                  );
                                })}
                              </Row>
                            </div>
                          </div>
                        </Container>

                        <Container className="d-mob caros">
                          <Row>
                            <CarouselComponent
                              status
                              statusTextRight="speakers"
                              swipeable
                              responsive={responsiveSpeaker}
                              autoPlay={false}
                            >
                              {card.title.map((el, idx) => {
                                return (
                                  <SpeakerCards
                                    title={el}
                                    image={card.image[idx]}
                                    subtext={card.subtext[idx]}
                                  />
                                );
                              })}
                            </CarouselComponent>
                          </Row>
                        </Container>
                      </section>

                      <section className="content-wrapper" id="1">
                        <Container>
                          <div className="contents mx-auto">
                            <div className="p14">
                              <p className="text-pink">
                                {pageContents[0].heading}
                              </p>
                            </div>

                            <div className="p16 text-white">
                              {parse(pageContents[0].subtext.html)}
                            </div>
                          </div>

                          <div className="cards-wrapper mx-auto d-dsk">
                            {pageContents[0].detailHeading.map((el, idx) => {
                              return (
                                <Cards
                                  icons={pageContents[0].detailIcon[idx].url}
                                  heading={el}
                                  content={pageContents[0].detailContent[idx]}
                                />
                              );
                            })}
                          </div>
                        </Container>

                        <Container className="caros  d-mob">
                          <div className="cards-wrapper-mob">
                            <CarouselComponent
                              responsive={responsive}
                              status
                              statusTextRight="reasons"
                              swipeable
                              autoPlay={false}
                            >
                              {pageContents[0].detailHeading.map((el, idx) => {
                                return (
                                  <Cards
                                    icons={pageContents[0].detailIcon[idx].url}
                                    heading={el}
                                    content={pageContents[0].detailContent[idx]}
                                  />
                                );
                              })}
                            </CarouselComponent>
                          </div>
                        </Container>
                      </section>

                      <section className="Timeline-section" id="2">
                        <Container>
                          <Row className="justify-content-center">
                            <Col lg={10}>
                              <div className="p14">
                                <p className="text-pink">
                                  {/* {pageContents[0].heading} */}
                                  {sectionName}
                                </p>
                              </div>
                            </Col>
                          </Row>

                          {timeline.map((el, idx, arr) => {
                            return (
                              <Row className="timeline justify-content-center">
                                <Col lg={2} xs={12} className="left-column">
                                  <p className="text-dark-grey">{el.time}</p>
                                  <p className="text-white">{el.agenda}</p>

                                  {arr.length - 1 !== idx && (
                                    <div className="lines d-dsk">
                                      <div className="dot"></div>
                                      <div className="line"></div>
                                      <div className="dot"></div>
                                    </div>
                                  )}
                                </Col>
                                {arr.length - 1 !== idx ? (
                                  <>
                                    <Col
                                      lg={4}
                                      xs={12}
                                      className="middle-column"
                                    >
                                      {el.title.length > 0 && (
                                        <>
                                          <p className="text-dark-grey">
                                            Title
                                          </p>
                                          {el.title.map((title, idx) => {
                                            return (
                                              <p className="text-light-white">
                                                {title}
                                              </p>
                                            );
                                          })}
                                        </>
                                      )}
                                    </Col>

                                    <Col
                                      lg={4}
                                      xs={12}
                                      className="right-column"
                                    >
                                      {el.speakerName.length > 0 && (
                                        <>
                                          <p className="text-dark-grey speaker-text">
                                            Speaker
                                          </p>
                                          {el.speakerName.map((speaker, i) => {
                                            return (
                                              <div className="speaker-box">
                                                <div>
                                                  <img
                                                    src={el.speaker[i].url}
                                                    className="speaker-image"
                                                    alt="speaker"
                                                  />
                                                </div>

                                                <div>
                                                  <p className="speaker-name">
                                                    {speaker}
                                                  </p>
                                                  <p className="speaker-title">
                                                    {el.speakerTitle[i]}
                                                  </p>
                                                </div>
                                              </div>
                                            );
                                          })}
                                        </>
                                      )}

                                      {arr.length - 1 !== idx && (
                                        <div className="lines d-mob">
                                          <div className="dot"></div>
                                          <div className="line"></div>
                                          <div className="dot"></div>
                                        </div>
                                      )}
                                    </Col>
                                  </>
                                ) : (
                                  <Col lg={8} xs={12}>
                                    <div className="Beerbox">
                                      <div className="offset-bg"></div>
                                      <div className="text-center">
                                        <SVGIcon
                                          name="glow-beer"
                                          classname="beer"
                                        />
                                      </div>
                                      <div>
                                        <p>Free beer, snacks & banter</p>
                                      </div>
                                    </div>
                                  </Col>
                                )}
                              </Row>
                            );
                          })}
                        </Container>
                      </section>

                      <section className="sponser-wrapper">
                        <Container>
                          <div className="sponser-div">
                            <h4>Special offers from</h4>
                            <Row>
                              {images.map(el => {
                                return (
                                  <Col lg={3} xs={6}>
                                    <img src={el.url} className="w-100" />
                                  </Col>
                                )
                              })}
                            </Row>
                          </div>
                        </Container>
                      </section>

                      {/* <section className="CTA-section">
                        <Container>
                          <Row className="justify-content-center">
                            <Col lg={10}>
                              <Row className="register-box justify-content-center">
                                <Col lg={6}>
                                  <p className="cta-text">
                                    We’ll see you there!
                                  </p>
                                </Col>

                                <Col lg={4}>
                                  <Buttons
                                    theme="primary pink w-100"
                                    text="REGISTER NOW"
                                    onClick={() => setPopUp(true)}
                                  />
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Container>
                      </section> */}

                      <section className="Faq-section" id="3">
                        <Container>
                          <div className="faq">
                            <div className="outer mx-auto">
                              <h2 className="faq-heading text-left">
                                We are sure you have questions
                              </h2>
                              <CustomAccordion items={faqs} />
                            </div>
                          </div>
                        </Container>
                      </section>
                    </div>
                  </Frame>
                </div>
              );
            })}
          </>
        )}
      />
    </div>
  );
};

export default Event;
